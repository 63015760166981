input[type="radio"] {
  display: none;
}

label {
 
  z-index: 10;
  cursor: pointer;
}

h3 {
  margin: 0;
  font-weight: 400;
  font-size: large !important;
}

.black-font {
  color: black !important;
}
.white-font {
  color: white !important;
}

.main-container {
  display: flex;
  vertical-align: top;
  width: 175px;
  height: 25px;
  border-radius: 100px;
  background-color: #7B90A6;
  
  
  
}

.switch {
  height: 19px;
  width: 56px;
  background-color: white;
  border-radius: 100px;
  position: absolute;
  margin-top: 3px;
  transition: ease-in;
}

/*---------------- LEFT ------------------- */
.switch.left-to-center {
  animation-name: leftToCenter;
  animation-duration: 0.5s;
}

.switch.left-to-right {
  animation-name: leftToRight;
  animation-duration: 0.5s;
}

.switch.left-position {
  margin-left: 3px;
}

.left-label {
  
  font-size: smaller;

}

#left + label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 56px;
  border-radius: 100px;
}

#left:checked + label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 56px;
  border-radius: 100px;
}

@keyframes leftToCenter {
  from {
    margin-left: 3px;
  }
  to {
    margin-left: 60px;
  }
}

@keyframes leftToRight {
  from {
    margin-left: 3px;
  }
  to {
    margin-left: 116px;
  }
}

/* -------------------- center ------------------ */
.switch.center-to-left {
  animation-name: centerToLeft;
  animation-duration: 0.5s;
}

.switch.center-to-right {
  animation-name: centerToRight;
  animation-duration: 0.5s;
}

.switch.center-position {
  margin-left: 60px;
}

.center-label {
  
  font-size: smaller;

}

#center + label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 56px;
  border-radius: 100px;
}

#center:checked + label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 56px;
  border-radius: 100px;
}

@keyframes centerToLeft {
  from {
    margin-left: 60px;
  }
  to {
    margin-left: 3px;
  }
}

@keyframes centerToRight {
  from {
    margin-left: 60px;
  }
  to {
    margin-right: 1px;
  }
}

/* ------------------- RIGHT ------------------- */
.switch.right-to-left {
  animation-name: rightToLeft;
  animation-duration: 0.5s;
}

.switch.right-to-center {
  animation-name: rightToCenter;
  animation-duration: 0.5s;
}

.switch.right-position {
  margin-left: 116px;
}

.right-label {
  
  font-size: smaller;
}

#right + label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 56px;
  border-radius: 100px;
}

#right:checked + label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 56px;
  border-radius: 100px;
}

@keyframes rightToLeft {
  from {
    
    margin-left: 116px;
  }
  to {
    margin-left: 3px;
  }
}

@keyframes rightToCenter {
  from {
    margin-left: 116px;
  }
  to {
    margin-right: 60px;
  }
}
